.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 36px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: sticky;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  /* z-index: 998; */
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.invalid-feedback-my {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.table-hover-error {
  border: solid 1px red;
  background-color: #ddd;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ff8383;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #ff8383;
}

.click a {
  display: block;
}

.ctablepp {
  overflow: auto;
  max-height: 500px;
  margin-bottom: 50px;
}

.ctable {
  overflow: auto;
  max-height: 600px;
  margin-bottom: 50px;
}

.mytable {
  overflow: auto;
}

.mytable th {
  min-width: 120px;
}

.mytable .myth {
  min-width: 100px;
}
.mytable .myth2 {
  min-width: 400px;
}

.mytable .myth3 {
  min-width: 200px;
}

.mytable .mythid {
  min-width: 100px;
}

.longth {
  min-width: 300px !important;
}

.longthmid {
  min-width: 150px !important;
}

.minbox {
  min-width: 100px !important;
}

.usertable .thc {
  width: 10px !important;
}

.usertable .th {
  width: 10px !important;
}

.btgap {
  margin-left: 300px;
}

.modal-90w {
  max-width: 90% !important;
}

.form-row1 {
  max-height: 90px !important;
}

.icon {
  color: white !important;
}

.datalist {
  display: none;
}

.largeicon {
  font-size: 30px !important;
}

.largeiconfont {
  font-size: 14px !important;
}

.finalpaymnet {
  margin-bottom: 50px;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.leaflet-container {
  width: 100%;
  height: 800px;
  z-index: 1;
}

.mtable {
  overflow: auto;
  max-height: 300px;
  margin-bottom: 50px;
}

.block-ui-container {
  z-index: 1 !important;
}

.btdown {
  margin-top: 30px;
}

.print-source {
  position: relative;
  width: 1000px;
  height: 450px;
  /* background-color: blue; */
  font-family: 'Arial Bold';
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 150%;
  margin-top: 50px;
  font-size: 20px !important;
  color: black;
}

.checkdate {
  font-size: 32px;
  letter-spacing: 3px;
  position: absolute;
  top: 10px;
  right: 85px;
}

.checkname {
  position: absolute;
  top: 80px;
  left: 50px;
}

.checkrupee {
  position: absolute;
  top: 140px;
  left: 50px;
  text-transform: capitalize;
  width: 450px;
}

.checkamout {
  position: absolute;
  top: 170px;
  right: 240px;
}

@media print {
  body * {
    visibility: visible !important;
  }
  .print-source {
    display: block;
    font-family: 'Arial Bold';
    font-style: normal;
  }
}

.checkmonth {
  padding-left: 32px;
}

.checkyear {
  padding-left: 120px;
}

.checkdateone {
  padding-left: 19px;
}

.famenuhi {
  width: 500px;
  height: 700px;
  overflow-y: scroll;
}

.licr {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px;
  color: #dc3545;
}

@keyframes blinking {
  0% {
    background-color: #06c3d1;
    border: 3px solid #666;
  }
  100% {
    background-color: #270da6;
    border: 3px solid #666;
  }
}

.blink {
  padding: 15px;
  width: 200px;
  height: 200px;
  animation: blinking 1s infinite;
  color: white;
  font-size: 18px;
}

.notblink {
  width: 200px;
  height: 200px;
  color: white;
  font-size: 18px;
  padding: 15px;
  background-color: teal;
  border: 3px solid #666;
}

.filltable {
  width: 100%;
  overflow: auto;
}

.filltablep {
  font-size: 24px;
  padding-left: 90px;
}

.dryertest {
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
}

.removep {
  padding: 0px;
  margin: 0px;
}

/* @page { size: auto;  margin: 0mm; } */

.mycode {
  padding: 0px;
  margin: 0px;
  font-family: 'Times New Roman', Times, serif !important;
  /* font-family: "Arial Bold" !important; */
  text-align: center !important;
  width: 283.46px !important;
  height: 100%;
  padding-top: 20px;
  margin-bottom: 50px;
  font-size: 12px !important;
}

.mycode p {
  margin: 0px !important;
  padding: 0px !important;
}

.dryerspan {
  margin-right: 5px;
}

.myul {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.myul li {
  display: inline;
}

.lotp {
  font-weight: bold;
  color: darkblue;
  font-size: 14px;
}

.lotpred {
  font-weight: bold;
  color: red;
  font-size: 14px;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

.blink_me1 {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#myt .react-bootstrap-table {
  display: block;
  width: 100%;
  max-height: 500px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#myt .react-bootstrap-table table {
  table-layout: auto !important;
}

#myt .react-bootstrap-table .table th,
#myt .react-bootstrap-table .table td {
  white-space: nowrap;
  text-align: right;
}

#myt .react-bootstrap-table .table th {
  background-color: white;
  position: sticky;
  top: 0;
}

.mytable1 {
  overflow: auto;
}

.mytable1 th {
  width: 100px;
}

.mytable1 .quan {
  width: 50px !important;
}

.fabricationtable {
  height: 400px;
  overflow: auto;
}

.revaluation {
  overflow: auto;
  min-height: 400px;
  max-height: 400px;
}

.revaluation table td {
  text-align: right;
}

.tdwidth {
  width: 300px;
}

.bg-danger {
  background-color: red !important;
}

.findash {
  font-size: 18px;
  color: green;
  font-weight: bold;
}

.findli {
  font-size: 14px;
  color: blueviolet;
  font-weight: bold;
}

.minred {
  color: red;
  font-weight: bold;
}

.mintable {
  max-width: 200px;
}

.trheight {
  height: 120px !important;
}

.ontop {
  display: none;
}

.barfont {
  min-height: 100px !important;
  font-size: 50px !important;
  color: red;
  font-weight: bolder !important;
  border: none !important;
  background-color: white !important;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.selecthightorder {
  height: 700px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.selecthight {
  height: 300px !important;
}

.clocktext {
  font-weight: bold;
  color: red;
  font-size: 50px;
}

.vl {
  border-left: 2px solid green;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

.selecthightorderwidth {
  width: 80% !important;
  overflow-x: scroll;
}

.form-group label {
  font-size: 18px !important;
}

.cread {
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: brown;
}

.cread a {
  color: white;
}

.widthpm {
  width: 200px;
}

.barwidth {
  width: 100% !important;
  overflow-x: scroll;
}

.apexcharts-yaxis-label {
  font-weight: bold !important;
  overflow-wrap: break-word;
}

.apexcharts-xaxis-label {
  padding-right: 100px !important;
}
